import React from "react";
import styled from "styled-components";
import { Layout } from "../components/containers";
// import SEO from "../components/marketing/Seo";
import { SectionTitle } from "../components/utility";

const PrivacyPolicy = () => {
  return (
    <Layout>
      {/* <SEO title="Privacy Policy" /> */}
      <Cover>
        <Wrapper>
          <SectionTitle>Digital Comparison Privacy Policy</SectionTitle>
          <SubWrapper />
          <Title>1 About this Privacy Policy</Title>
          <Paragraph>
            1.1. This Privacy Policy describes how AARU Pty Ltd manages personal
            information about any person whose data is collected and processed
            by our website known as “Digital Comparison” at
            digitalcomparison.com.au.
          </Paragraph>
          <Paragraph>
            1.2. We are committed to complying with our privacy obligations in
            accordance with all applicable data protection laws, including the
            Australian Privacy Principles contained in Schedule 1 to the Privacy
            Act 1988 (Cth).
          </Paragraph>
          <Paragraph>
            1.3. If we decide to change this Privacy Policy, we will post the
            updated version on this webpage. Our policy is to be completely
            transparent about our privacy practices.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>2 Our website</Title>
          <Paragraph>
            2.1. We own and operate the Digital Comparison website. Digital
            Comparison allows customers to submit requests for quotation or
            information to us concerning third party products and services. We
            do not supply any goods or services the subject of those requests.
          </Paragraph>
          <Paragraph>
            2.2. We are not a party to any transaction or contract for the
            supply of goods or services as between any user of the website and
            any supplier.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            3 The types of personal information we collect and hold about users
          </Title>
          <Paragraph>
            3.1. If you complete a quote request form on the Digital Comparison
            website, we will collect and hold all information entered into the
            form. Our quote request forms are designed to collect a customer’s
            name and contact person name, service address(es), company name(s),
            email address(es) and mobile and landline phone number(s),
            information about the customer’s requirements for products and
            services, information concerning any existing related products and
            services that a customer may have, and any other information that
            may otherwise be reasonably required by a third party supplier in
            order to provide a quote or other information concerning the goods
            and/or services to the customer the subject of the quote request.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>4 How we collect personal information</Title>
          <Paragraph>
            4.1. Our policy is to not collect personal information by means that
            are unfair or unreasonably intrusive in the circumstances.
          </Paragraph>
          <Paragraph>
            4.2. We only collect information about customers when they complete
            our quote request forms on our website.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>5 Analytics data</Title>
          <Paragraph>
            5.1. We also collect information about users through their use of
            Digital Comparison, known as analytics data. Such analytics data
            includes information about devices accessing the website, the amount
            of time a user spends on the website and in which parts of it, and
            the path navigated through it. However, all such information is de-
            identified data and is not collected in a form that could reasonably
            be expected to identify an individual. In any event, we only use
            analytics data for the following purposes: <br /> (a) to help us
            review, enhance and improve the website (for statistical or research
            purposes); and
            <br />
            (b) to develop case studies and marketing material without
            identifying any individual.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>6 How we use personal information</Title>
          <Paragraph>
            6.1. Information about how we use personal information is set out
            below:
          </Paragraph>
          <SubWrapper>
            <Title>
              Category : Visitors of Digital Comparison (including users)
            </Title>
            <Title pad="1rem 0 0 0">
              How we use and process that personal information
            </Title>
            <Paragraph>
              • To provide the information in quote request forms to suppliers.
            </Paragraph>
            <Paragraph>
              • We may contact customers on their mobile numbers by calling or
              sending an SMS regarding their quote requests and also to promote
              third party goods and services.
            </Paragraph>
            <Paragraph>
              • In order to store user data in databases and systems in our
              hosting environments at third party data centres.
            </Paragraph>
            <Paragraph>
              • To send newsletters and other communications concerning products
              and services offered by third party suppliers.
            </Paragraph>
            <Paragraph>
              • To issue bills and invoices to suppliers and to enforce the
              obligation of suppliers to pay our fees.
            </Paragraph>
            <Paragraph>
              • To carry out security audits, investigate security incidents and
              implement security processes and procedures that require access to
              user personal information.
            </Paragraph>
            <Paragraph>
              • To communicate with customers of our website about new services
              and products available to them.
            </Paragraph>
            <Paragraph>• To handle complaints.</Paragraph>
          </SubWrapper>
          <SubWrapper>
            <Title pad="1rem 0 0 0">
              Our reason for collecting the personal information
            </Title>
            <Paragraph>
              • Necessary for our legitimate interests in order to operate and
              grow our business, in order to administer and allow our users to
              use the website.
            </Paragraph>
            <Paragraph>
              • To comply with our legal and statutory obligations.
            </Paragraph>
          </SubWrapper>
        </Wrapper>
        <Wrapper>
          <Title>7 How we hold and secure personal information</Title>
          <Paragraph>
            7.1. We hold and store personal information that we collect in our
            offices, computer systems, and third party owned and operated
            hosting facilities. In particular:
            <br />
            (a) we use systems in hosting facilities operated by reputable
            hosting providers;
            <br />
            (b) personal information that is provided to us via email is held on
            our servers or those of our cloud-based email providers;
            <br />
            (c) personal information is held on computers and other electronic
            devices in our offices and at the premises of our personnel;
            <br />
            (d) we hold personal information that is provided to us in hard copy
            in files on our business premises.
          </Paragraph>
          <Paragraph>
            7.2. We take reasonable steps to protect personal information that
            we hold using such security safeguards as are reasonable in the
            circumstances to take against loss, unauthorised access,
            modification and disclosure and other misuse and to implement
            technical and organisational measures to ensure a level of
            protection appropriate to the risk of accidental or unlawful
            destruction, loss, alteration, unauthorised disclosure of, or access
            to, personal information transmitted, stored or otherwise processed
            by us.
          </Paragraph>
          <Paragraph>
            7.3. We:
            <br />
            (a) only use reputable hosting providers to host personal
            information;
            <br />
            (b) implement passwords and access control procedures into our
            computer systems;
            <br />
            (c) maintain physical security measures in our buildings and offices
            such as door and window locks and visitor access management, cabinet
            locks, surveillance systems and alarms to ensure the security of
            information systems (electronic or otherwise);
            <br />
            (d) perform security testing and maintain other electronic
            (e-security) measures for the purposes of securing personal
            information, such as passwords, anti-virus management and firewalls;
            <br />
            (e) carry out security audits of our systems which seek to find and
            eliminate any potential security risks in our electronic and
            physical infrastructure as soon as possible;
            <br />
            (f) require all of our employees, agents and contractors to comply
            with privacy and confidentiality provisions in their employment
            contracts and subcontractor agreements that we enter into with them;
            <br />
            (g) use SSL encryption on our website;
            <br />
            (h) implement anti-virus and security controls for email and other
            applicable computer software and systems; and
            <br />
            (i) have data backup archiving, data breach response plans and
            disaster recovery processes in place.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>8 Disclosure of personal information</Title>
          <Paragraph>
            8.1. We only disclose personal information that we collect to third
            parties as follows:
            <br />
            (a) we disclose information that we collect from our quote request
            forms to any third party suppliers in our absolute discretion;
            <br />
            (b) in order to host databases that are integrated into Digital
            Comparison, we engage reputable hosting providers who host those
            databases on our behalf (currently Netlify);
            <br />
            (c) when carrying out direct marketing calls and emails by our staff
            or those engaged on our behalf or to promote our website services.
            All individuals will be given the opportunity to ‘opt out’ of any
            direct marketing calls, text messages or emails;
            <br />
            (d) when performing contracts we may outsource certain obligations
            to third party contractors in accordance with our contractual rights
            (such as hosting, software development and other professional
            service providers). Professional services carried out by them may
            require access to personal information.
            <br />
            (e) we ensure that all staff and contractors are aware of their
            information security responsibilities, are appropriately trained to
            meet those responsibilities and have entered into agreements which
            require them to comply with privacy and confidentiality obligations
            that apply to personal information that we provide to them;
            <br />
            (f) when providing information to our legal, accounting or financial
            advisors/representatives or insurers, or to our debt collectors for
            debt collection purposes or when we need to obtain their advice, or
            where we require their representation in relation to a legal
            dispute;
            <br />
            (g) where a person provides written consent to the disclosure of
            their personal information;
            <br />
            (h) where it is brought to our attention that specific personal
            information needs to be disclosed to protect the safety or vital
            interests of any person;
            <br />
            (i) if we are contacted by any person who represents to us that they
            are a user of Digital Comparison, for security purposes, we will
            only discuss the personal information that we hold about them with
            them if they identify themselves accurately and truthfully;
            <br />
            (j) where it is brought to our attention that specific personal
            information needs to be disclosed to protect the safety or vital
            interests of any person;
            <br />
            (k) to avoid prejudice to the maintenance of the law by any public
            sector agency, including the prevention, detection, investigation,
            prosecution, and punishment of offences;
            <br />
            (l) for the enforcement of a law imposing a pecuniary penalty;
            <br />
            (m) for the protection of public revenue;
            <br />
            (n) for the conduct of proceedings before any court or tribunal
            (being proceedings that have been commenced or are reasonably in
            contemplation); or
            <br />
            (o) where required by law.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>9 Third party websites</Title>
          <Paragraph>
            9.1. Digital Comparison may include links to third party websites.
            Our linking to those websites does not mean that we endorse or
            recommend them. We do not warrant or represent that any third party
            website operator complies with applicable data protection laws.
            Users and website visitors should consider the privacy policies of
            any relevant third party website prior to sending personal
            information to them.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            10 Interacting with us without disclosing personal information
          </Title>
          <Paragraph>
            10.1. Any person has the option of not identifying themselves or
            using a pseudonym or not providing other personal information when
            submitting a quote request form on Digital Comparison. However, if
            we are not provided with valid contact details, the third party
            suppliers who we supply the information entered into the quote
            request form will not be able to contact the person.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>11 Offshore disclosure</Title>
          <Paragraph>
            11.1. We may disclose personal information to our offshore service
            providers and personnel who assist us with providing the services
            available via Digital Comparison and to assist us with the operation
            of our businesses generally. We will take reasonable steps to ensure
            that such overseas recipients do not breach the Australian Privacy
            Principles in relation to personal information.
          </Paragraph>
          <Paragraph>
            11.2. At present, all personal information that we collect is held
            in data centres located in [insert].
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            12 How to access and correct personal information held by us
          </Title>
          <Paragraph>
            12.1. We will handle all requests for access to personal information
            in accordance with our statutory obligations. We may require payment
            of a reasonable fee by any person who requires access to their
            personal information that we hold, except where such a fee would be
            contrary to applicable law.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>13 Our contact details</Title>
          <Paragraph>
            13.1. Any person who wishes to contact us for any reason regarding
            our privacy practices or the personal information that we hold about
            them, or make a privacy complaint, may contact us using the
            following details:
          </Paragraph>
          <Paragraph>
            Privacy Representative <br />
            Digital Comparison <br />
            contact@digitalcomparison.com.au
            <br /> Level 1, 80-82 Bell Street <br />
            HEIDELBERG HEIGHTS VIC 3081
          </Paragraph>
          <Paragraph>
            13.2. We will use our best endeavours to resolve any privacy
            complaint with the complainant within a reasonable time frame given
            the circumstances. This may include working with the complainant on
            a collaborative basis or otherwise resolving the complaint.
          </Paragraph>
          <Paragraph>
            13.3. If the complainant is not satisfied with the outcome of a
            complaint or they wish to make a complaint about a breach of the
            Australian Privacy Principles, they may refer the complaint to the
            Office of the Australian Information Commissioner who can be
            contacted using the following details:
          </Paragraph>
          <Paragraph>
            Telephone: 1300 363 992 <br />
            Email: enquiries@oaic.gov.au <br />
            Address: GPO Box 5218, Sydney NSW 2001
          </Paragraph>
        </Wrapper>
      </Cover>
    </Layout>
  );
};

export default PrivacyPolicy;

const SubWrapper = styled.div`
  padding: 2rem 0 0 0;
`;

const Cover = styled.section`
  padding: 15vh 2rem;
  width: 85%;
  margin: auto;
`;

const Wrapper = styled.div`
  padding: 3rem 0 0 0;
  width: 85%;
  margin: auto;
  @media only screen and (max-width: 75em) {
    width: 100%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
`;

const Title = styled.h2`
  padding: ${(props) => props.pad};
  font-family: "Avenir-Medium";
  font-size: 2rem;
  line-height: 2.6rem;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${(props) => props.color || "#242424"};
  @media only screen and (max-width: 75em) {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: ${(props) => props.align || "left"};
  }
`;
const Paragraph = styled.p`
  padding: 0.5rem 0;
  font-family: "Avenir-Book";
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #242424;
  @media only screen and (max-width: 75em) {
    font-size: 1.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 1.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.4rem;
    text-align: ${(props) => props.align || "left"};
  }
`;
